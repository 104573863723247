
.login-form{
    width: 50vw;
    display: grid;
    background: white;
    padding: 50px !important;
    font-size: 1vw;
    border-radius:0 10px 10px 0 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  input{
    margin-bottom: 30px;
  }
  .image{
   /* background-image: url('./qy81P.png'); */
  
  }
  .login-box{
    display: grid;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
  .container{
    width: 70% !important;
  }
  
  
  .background{
    background: linear-gradient(to left, rgba(1, 203, 196, 0.781), rgba(96, 248, 243, 0.411));
    width: 100vw;
    height: 100vh;
  }
  button.mt-3.mb-3.px-auto.text-center {
    font-weight: 500;
    text-align: center;
    padding: 0 110px !important;
    font-size: 18px !important;
  }
 
  .btn{
    background-color: rgba(3, 127, 123, 1) !important;
    font-weight: 500 !important;
    letter-spacing: .3px;
    height: 50px;
    width: 100%;
    font-size: medium;
  
  }
  .mobile-img{
    display: grid;
  
  }
  .left-background{
    background-color: rgba(3, 127, 123, 1);
    color: white;
    padding: 40px!important;
    padding-bottom: 0 !important;
    border-radius: 10px 0 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .left-background img{
    height: 60%;
  }
  
  @media only screen and (max-width: 425px) {
  form{
    width: 66vw;
  }
  .login-form{
    padding: 25px !important;
    font-size: .8rem;
    border-radius: 0 0 10px 10px ;
    
  }
  .left-background{
    padding: 20px !important;
    padding-bottom: 0 !important;
    border-radius: 10px 10px 0 0;
  }
  .container{
    width: 80% !important;
  }
  .login-box{
    
  }
  .btn{
    font-size: 14px !important;
  }
  
  }
