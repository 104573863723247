
.course-main{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    height: 100vh;
}
.course{
    width: 100%;

}
.course-inputss{
  width: 100%;
  max-width: 1060px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;

}
.course-header {
    background: linear-gradient(0deg, rgba(3, 127, 123, 0.7), rgba(1, 203, 196, 1));
   width: 100%;
   height: 100px;
   display: flex;
   /* padding-left: 10%; */
   /* justify-content: space-around; */
   align-items: center;
   padding: 0;
   
}
.course-header p{
    font-size: 30px;
    font-weight: bolder;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.drag-drop{
   
    width: 100%;
    height: 300px;
    border: 2px dotted rgba(27, 127, 134, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    background-color: rgba(1, 203, 196, 0.40);
    max-width: 1000px;
    cursor: pointer;
   /* min-width: 250px; */
}
.drag-drop {
  width: 90%;
 

 }
 .drag-main{
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    
 }

  
  .dropzone-container {
   
   
    position: relative;
    text-align: center;
     
  }

  
  .drag-drop-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  .drag-drop-text img{
    width: 80px;
  }
  
  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
   
  }
  .drop-img{
    width: 100%;
    height: 300px;
    border-radius: 20px;
    padding: 10px;
  }
.course-inputs-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-inputs{
  width: 100%;
  max-width: 1060px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.filled-multiline-flexible{
  width: 46%;
  border-radius: 40px;
}
.uploaded-video{
     object-fit: contain;
     width: 100%;height: 100%;
}
.pickers{
  display: flex;
  justify-content: center;
}



.checkbox-wrapper-33 {
  --s-xsmall: 0.625em;
  --s-small: 1.2em;
  --border-width: 1px;
  --c-primary: rgba(1, 203, 196, 1);
  --c-primary-20-percent-opacity: rgb(1 203 196 / 40%);
  --c-primary-10-percent-opacity: rgb(1 203 196 / 40%);
  --t-base: 0.4s;
  --t-fast: 0.2s;
  --e-in: ease-in;
  --e-out: cubic-bezier(0.11, 0.29, 0.18, 0.98);
}

.checkbox-wrapper-33 .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox-wrapper-33 .checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(1, 203, 196, 1);
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 10px;
}

.checkbox-wrapper-33 .checkbox + .checkbox {
  margin-top: var(--s-small);
}

.checkbox-wrapper-33 .checkbox__symbol {
  display: inline-block;
  display: flex;
  margin-right: calc(var(--s-small) * 0.7);
  border: var(--border-width) solid var(--c-primary);
  position: relative;
  border-radius: 0.1em;
  width: 1.5em;
  height: 1.5em;
  transition: box-shadow var(--t-base) var(--e-out),
    background-color var(--t-base);
  box-shadow: 0 0 0 0 var(--c-primary-10-percent-opacity);
}

.checkbox-wrapper-33 .checkbox__symbol:after {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 0.25em;
  height: 0.25em;
  background-color: var(--c-primary-20-percent-opacity);
  opacity: 0;
  border-radius: 3em;
  transform: scale(1);
  transform-origin: 50% 50%;
}

.checkbox-wrapper-33 .checkbox .icon-checkbox {
  width: 1em;
  height: 1em;
  margin: auto;
  fill: none;
  stroke-width: 3;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  color: var(--c-primary);
  display: inline-block;
}

.checkbox-wrapper-33 .checkbox .icon-checkbox path {
  transition: stroke-dashoffset var(--t-fast) var(--e-in);
  stroke-dasharray: 30px, 31px;
  stroke-dashoffset: 31px;
}

.checkbox-wrapper-33 .checkbox__textwrapper {
  margin: 0;
}

.checkbox-wrapper-33 .checkbox__trigger:checked + .checkbox__symbol:after {
  -webkit-animation: ripple-33 1.5s var(--e-out);
  animation: ripple-33 1.5s var(--e-out);
}

.checkbox-wrapper-33
  .checkbox__trigger:checked
  + .checkbox__symbol
  .icon-checkbox
  path {
  transition: stroke-dashoffset var(--t-base) var(--e-out);
  stroke-dashoffset: 0px;
}

.checkbox-wrapper-33 .checkbox__trigger:focus + .checkbox__symbol {
  box-shadow: 0 0 0 0.25em var(--c-primary-20-percent-opacity);
}

@-webkit-keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(20);
  }
}

@keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale(20);
  }
}




.submit {
  padding: 15px;
  border: 2px solid #17C3B2;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: #17C3B2;
  width: 170px;
  border-radius: 15px;
 }
 
 .submit::before {
  content: '';
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #17C3B2;
  transition: .5s ease;
  display: block;
  z-index: -1;
 }
 
 .submit:hover::before {
  width: 105%;
 }
 
 .submit:hover {
  color: white;
 }
 .save-button{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
 }

.lessons{
  height: 100vh;
  overflow-y: scroll;
}
 .lessons .lessson-head{
  font-size: 35px;
  font-weight: bolder;
  text-decoration: underline;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 }
 .filled-multiline-flexible1{
  width: 100%;
 }
 .course-inputs2{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 20px;
 }

 