@media only screen and (max-width: 900px) {
  .sidebar-container {
    position: fixed; /* Change to fixed for small screens */
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 10;
    background-color: rgba(1, 203, 196, 1);
  }
  .sidebar-container.visible {
    transform: translateX(0);
  }
}
@media only screen and (min-width:900px) 
{
.burger-menu{
  display: none;
}  
}
@media only screen and (min-width: 901px) {
  .sidebar-container {
    position: relative; 
  }
  .cross-img-contained {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .cross-img-contained {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
    padding-right: 22px;
  }
  .logo-img-contained {
    height: 85px;
  }
}
