

.preview-buttons{
    display: flex;
    justify-content: space-between;
   margin-top: 10px;
   
}
.preview-buttons button{
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    color: #17C3B2;
}
.preview-buttons button img{
    width: 30px;
}
