.chat-inbox {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
    padding-top: 20px;
    
  }
  .chat-inbox::-webkit-scrollbar {
    width: 10px;
  }
  .chat-inbox::-webkit-scrollbar-thumb {
    background: rgba(1, 203, 196, 1); 
    border-radius: 10px;
  }
  

  .main-chat{
    width: 70%;
    display: flex;
  }
  

  
  .chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;

    
  }
  
  .chat-item.active {
    background-color: #f0f0f0;
  }
  
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ccc; /* Placeholder for profile image */
    margin-right: 10px;
  }
  .profile-img img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
  }
  
  .message-info {
    flex-grow: 1;
  }
  
  .username {
    font-weight: bold;
  }
  
  .last-message {
    color: #888;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .time {
    margin-right: 10px;
    color: #888;
  }
  
  .unread-messages {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(1, 203, 196, 1);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  
  .chat-window {
    /* Style chat window as per your design */
  }
  .chat-window {
    width: 100%;
    height: 100vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .chat-header {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  
  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc; /* Placeholder for profile image */
    margin-right: 10px;
  }
  
  .username {
    font-weight: bold;
  }
  
  .messages {
    overflow-y: auto;
    padding: 10px;
    height: calc(100% - 120px); 
   
  }
  
  .chat-input {
    padding: 10px;
    /* margin-bottom: 30px !important; */
    display: flex;
    align-items: center !important;
    justify-content: space-between;
  }
  
  .chat-input input {
    flex-grow: 1;
    margin-right: 10px;
    height: 50px;
    border-radius: 30px;
    padding-left: 20px;
  }
  
  .chat-input button {
    height: 50px;
    width: 50px;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .chat-input button img{
    width: 30px;
  }
  .messages {
    display: flex;
    flex-direction: column;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    max-width: 70%;
  }
  
  .message-text {
    word-wrap: break-word;
  }
  
  .message-time {
    font-size: 0.8rem;
    color: #888;
  }
  
  .received {
    align-self: flex-start;
    background-color: #e0e0e0;
  }
  
  .sent {
    align-self: flex-end;
    background-color: rgba(1, 203, 196, 1);
    color: white;
  }
  .no-chat-selected-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
   
  }
  .no-chat-selected-message p{
    font-size: 25px;
    font-weight: bolder;
  }
  .no-chat-selected-message img{
    width: 100px;
  }
  .context-menu {
    position: fixed;
    background-color: rgb(43, 43, 43);
    border: 1px solid #0a0a0a;
    padding: 5px 10px;
    z-index: 9999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: white;
  }
  
  .context-menu-item {
    cursor: pointer;
  }
  
/* chat.css */

/* Add shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -1000px;
  }
  100% {
    background-position: 1000px;
  }
}

/* Shimmer effect */
.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #f6f7f8 4%, #edeef1 25%, #f6f7f8 36%);
  background-size: 1000px 100%;
}

  
  