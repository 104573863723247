/* Style for the form container */
.create-formm {
  background-color: #f7f7f7; /* Light grey background */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Style for each input field */
.create-formm input[type="text"],
.create-formm input[type="number"],
.create-formm input[type="checkbox"],
.create-formm select,
.create-formm textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

/* Style for the input field focus state */
.create-formm input[type="text"]:focus,
.create-formm input[type="number"]:focus,
.create-formm input[type="checkbox"]:focus,
.create-formm select:focus,
.create-formm textarea:focus {
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue shadow on focus */
  outline: none;
}

/* Style for the form labels */
.create-formm label {
  margin-top: 10px;
  font-weight: bold;
  display: block;
  font-size: 14px;
}

/* Style for the form buttons */
.create-formm .btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 2px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.create-formm .btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.create-formm .btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 2px 2px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.create-formm .btn-secondary:hover {
  background-color: #5a6268; /* Darker grey on hover */
}
