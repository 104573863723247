.sidebar-container {
  width: 100%;
  max-width: 270px;
  position: relative; /* Change to relative */
  top: 0;
  height: 100vh;
  z-index: 0;
}

.logo-img-contained {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  object-fit: contain;
}
.logo-img-contained img {
  width: 100px;
}
.sidebar-content-set {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  background-color: rgba(1, 203, 196, 1);
}
.sidebar-content-set-flex {
  /* background-color: rgba(1, 203, 196, 0.507); */
  width: 255px;
  /* border-radius: 24px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
}
.custom-button {
  width: 232px;
  height: 50px;
  border-radius: 17px;
  /* background-color: rgba(1, 145, 156, 0.61); */
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;
  padding-left: 23px;
}
.dashboard-contain {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}
.burger-menu{
  height: 30px;
  background-color: transparent;
  border: none;
  z-index: 0;
  position: fixed;
  margin-top: 30px;
  width: 100px;
}