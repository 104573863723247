.checkbox-wrapper-4 * {
    box-sizing: border-box;
    color: white;
  }
  
  .checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: inline-block;
  }
  
  .checkbox-wrapper-4 .cbx:not(:last-child) {
    margin-right: 6px;
  }
  
  .checkbox-wrapper-4 .cbx {
    background: linear-gradient(
      90deg,
      #17C3B2 0%,
      rgb(1 203 196 / 70%) 100%
    );
  }
  
  .cbx:not(:hover) span svg {
    color: red;
    stroke: red;
  }
  
  .checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  
  .checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
  }
  
  .checkbox-wrapper-4 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  
  .checkbox-wrapper-4 .cbx span:last-child {
    padding-left: 8px;
    line-height: 18px;
  }
  
  .checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: #fff;
  }
  
  .checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
  }
  
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
    background: #fff;
    border-color: #fff;
    transition: rotate 2s;
    transform: rotateZ(45deg);
    animation: wave-4 0.6s ease;
  }
  
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx:not(:hover) span:first-child {
    background: linear-gradient(
      90deg,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 100%
    );
    border-color: linear-gradient(
      90deg,
      rgba(230, 120, 2, 1) 0%,
      rgba(126, 28, 145, 1) 100%
    );
  }
  
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  
  .checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
  
  @media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
      width: 100%;
      display: inline-block;
    }
  }
  
  @-moz-keyframes wave-4 {
    33% {
      transform: scale(1.3);
    }
    66% {
      transform: scale(0.8);
    }
  }
  
  @-webkit-keyframes wave-4 {
    33% {
      transform: scale(1.3);
    }
    66% {
      transform: scale(0.8);
    }
  }
  
  @-o-keyframes wave-4 {
    33% {
      transform: scale(1.3);
    }
    66% {
      transform: scale(0.8);
    }
  }
  
  @keyframes wave-4 {
    33% {
      transform: scale(1.3);
    }
    66% {
      transform: scale(0.8);
    }
  }
  .date-pickers{
    min-width: 200px;
}
.date-inputs{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
} 
@media only screen and (max-width:832px) 
{
.date-inputs{
    flex-direction: column;
    justify-content: center;
}  
}