.loader {
  --size-loader: 50px;
  --size-orbe: 10px;
  width: var(--size-loader);
  height: var(--size-loader);
  position: relative;
  transform: rotate(45deg);
}

.orbe {
  position: absolute;
  width: 100%;
  height: 100%;
  --delay: calc(var(--index) * 0.1s);
  animation: orbit7456 ease-in-out 1.5s var(--delay) infinite;
  opacity: calc(1 - calc(0.2 * var(--index)));
}
.actions{
  min-width: 250px;
}
.orbe::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: var(--size-orbe);
  height: var(--size-orbe);
  background-color: rgba(1, 203, 196, 1);
  box-shadow: 0px 0px 20px 2px rgba(3, 127, 123, 0.7);
  border-radius: 50%;
}

.loader-main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

/* New CSS for overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.411); /* Adjust opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

  
  @keyframes orbit7456 {
    0% {
    }
  
    80% {
      transform: rotate(360deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input {
    width: 100%; /* Adjust width as needed */
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(1, 203, 196, 1);
    outline: none;
    padding: 18px 36px;
    background-color: #fff;
    transition: all .5s ease-in-out;
    color: black;
}

.input::placeholder {
    color: #ccc; /* Placeholder color */
}

.icon {
    position: absolute;
    left: 10px; /* Adjust icon position as needed */
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
    fill: rgba(1, 203, 196, 1);
}

.main-input-search {
    display: flex;
    justify-content: flex-end;
    margin-right: 60px;
    margin-top: 20px;
}




  .course-header .container{
   
    display: flex;
    flex: 1;
    justify-content: center;
    /* left:100px;     */
}

.course-header h3{
    /* font-family: Lato; */
    font-weight: 400;
    display: inline-block;
    color: #222222;
    font-size: 40px;
    line-height: 48px;
    /* margin-bottom: 26px; */
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid #dcdcdc;
  background: #e0e0e0;
  box-shadow: 7px 7px 23px #bebebe, -7px -7px 23px #ffffff;
  overflow: hidden;
  border-radius: 60px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.chbox:checked + .slider:before {
  background: white;
  box-shadow: none;
}

.chbox:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.slider {
  color: #9a9a9a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-family: sans-serif;
}

.slider--0 {
  color: white;
  font-weight: 600;
  background-color: rgba(1, 203, 196, 1);
}

.slider--1 div {
  transition: 0.5s;
}

.slider--1 div {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
}

.chbox:checked ~ .slider--1 div:first-child {
  transform: translateY(-100%);
  transition-delay: 1s;
}

.chbox:checked ~ .slider--1 div:last-child {
  transform: translateY(100%);
  transition-delay: 1s;
}

.chbox:checked ~ .slider--2 {
  transform: translateX(100%);
  transition-delay: 0.5s;
}

.chbox:checked ~ .slider--3 {
  transform: translateX(-100%);
  transition-delay: 0s;
}

.slider--1 div:first-child {
  transform: translateY(0);
  top: 0;
  background-color: #f3f3f3;
  transition-delay: 0s;
}

.slider--1 div:last-child {
  transform: translateY(0);
  bottom: 0;
  background-color: #f3f3f3;
  border-top: 1px solid #e0e0e0;
  transition-delay: 0s;
}

.slider--2 {
  background-color: #e6e6e6;
  transition-delay: 0.5s;
  transform: translateX(0);
  border-left: 1px solid #d2d2d2;
}

.slider--3 {
  background-color: #d2d2d2;
  transition-delay: 1s;
  transform: translatex(0);
  border-right: 1px solid #d2d2d2;
}






.checkbox-wrapper input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper *,
  .checkbox-wrapper *::before,
  .checkbox-wrapper *::after {
  box-sizing: border-box;
}

.checkbox-wrapper .toggleButton {
  cursor: pointer;
  display: block;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: transform 0.14s ease;
}

.checkbox-wrapper .toggleButton:active {
  transform: rotateX(30deg);
}

.checkbox-wrapper .toggleButton input + div {
  border: 3px solid rgba(1, 203, 196, 1);
  border-radius: 50%;
  position: relative;
  width: 44px;
  height: 44px;
}

.checkbox-wrapper .toggleButton input + div svg {
  fill: none;
  stroke-width: 3.6;
  stroke: rgb(0, 0, 0);
  stroke-linecap: round;
  stroke-linejoin: round;
  width: 44px;
  height: 44px;
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  z-index: 1;
  stroke-dashoffset: 124.6;
  stroke-dasharray: 0 162.6 133 29.6;
  transition: all 0.4s ease 0s;
}

.checkbox-wrapper .toggleButton input + div:before,
  .checkbox-wrapper .toggleButton input + div:after {
  content: "";
  width: 3px;
  height: 16px;
  background: red;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 5px;
}

.checkbox-wrapper .toggleButton input + div:before {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  -webkit-animation: bounceInBefore-44 0.3s linear forwards 0.3s;
  animation: bounceInBefore-44 0.3s linear forwards 0.3s;
}

.checkbox-wrapper .toggleButton input + div:after {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  -webkit-animation: bounceInAfter-44 0.3s linear forwards 0.3s;
  animation: bounceInAfter-44 0.3s linear forwards 0.3s;
}

.checkbox-wrapper .toggleButton input:checked + div svg {
  stroke-dashoffset: 162.6;
  stroke-dasharray: 0 162.6 28 134.6;
  transition: all 0.4s ease 0.2s;
}

.checkbox-wrapper .toggleButton input:checked + div:before {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  -webkit-animation: bounceInBeforeDont-44 0.3s linear forwards 0s;
  animation: bounceInBeforeDont-44 0.3s linear forwards 0s;
}

.checkbox-wrapper .toggleButton input:checked + div:after {
  opacity: 0;
  transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  -webkit-animation: bounceInAfterDont-44 0.3s linear forwards 0s;
  animation: bounceInAfterDont-44 0.3s linear forwards 0s;
}

@-webkit-keyframes bounceInBefore-44 {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
  }

  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInBefore-44 {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
  }

  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}

@-webkit-keyframes bounceInAfter-44 {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
  }

  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes bounceInAfter-44 {
  0% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
  }

  80% {
    opacity: 1;
    transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
}

@-webkit-keyframes bounceInBeforeDont-44 {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInBeforeDont-44 {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
  }
}

@-webkit-keyframes bounceInAfterDont-44 {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes bounceInAfterDont-44 {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 0;
    transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
  }
}
.user-verification{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
}
.user-verification p{
    font-size: large;
    font-weight: 600;
    margin: 0;
    padding-right: 10px;
}

.dropzone-container {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lesson-image-container {
  width: 100%;
  height: 100%;
  background-size: contain; /* Fit the image within the container */
  background-position: center;
  background-repeat: no-repeat;
}

.drag-drop-text {
  margin: 0;
  text-align: center;
}

.drag-drop-text img {
  display: block;
  margin: 0 auto;
}
.video-preview {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* Aspect ratio 16:9 */
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.react-pdf__Page__textContent{
  display: none;
}
.textLayer{
  display: none;
}
.react-pdf__Page__annotations{
  display: none;
}
.balance{
  margin-top: 20px;
 
}
.balance input{
  /* height: 42px; */
  border-radius: 15px;
  margin-right: 20px;
  margin-top: 10px;
  border: 1px solid rgb(105, 103, 103);
  padding: 10px;
  /* margin-top: 5px; */
}



.payment-checkbox{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
}
.payment-checkbox p{
  margin-top: 1.5% !important;
  padding-right: 5px;
}

.toggle-button-coverrrr {
  display: table-cell;
  position: relative;
  width: auto;
  height: 140px;
  box-sizing: border-box;
}

.button-coverrrr {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-coverrrr:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-coverrrr,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.checkboxxxx {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

#button-3 .knobs:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 4px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #f44336;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkboxxxx:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

#button-3 .checkboxxxx:checked:active + .knobs:before {
  margin-left: -26px;
}

#button-3 .checkboxxxx:checked + .knobs:before {
  content: "";
  left: 42px;
  background-color: #03a9f4;
}

#button-3 .checkboxxxx:checked ~ .layer {
  background-color: #fcebeb;
}